import React, { useState } from "react";
import "../styles/incident.css";

function Incident({ incident: inc }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="incident">
      {!open ? (
        <>
          {inc.img && <img src={inc.img} alt="photo of an incident" />}
          <p>{inc.year}</p>
          <h2 style={{ marginBottom: 50 }}>{inc.title}</h2>
          <div>
            <button onClick={() => setOpen(true)}>Read more</button>
          </div>
        </>
      ) : (
        <>
          <h2 style={{ paddingTop: 40 }}>{inc.header}</h2>
          <p style={{ textAlign: "justify", marginBottom: 50 }}>{inc.text}</p>
          <div>
            <button onClick={() => setOpen(false)}>Back</button>
          </div>
        </>
      )}
    </div>
  );
}

export default Incident;
