import React, { useState } from "react";
import Layout from "../components/Layout";
import "../styles/global.css";
import "../styles/pageHeader.css";
import "../styles/incidents.css";
import Incident from "../components/Incident";
import { data } from "../lib/incidents";

const menu = [
  [2020, 2020, "2020-Recent"],
  [2018, 2019, "2018-2019"],
  [2015, 2017, "2015-2017"],
  [2012, 2014, "2012-2014"],
  [2006, 2008, "2006-2008"],
  [2001, 2005, "2001-2005"],
];

function Incidents() {
  const [incs, setIncs] = useState(filterByYear(2020, 2020));
  const [selected, setSelected] = useState(0);

  function filterByYear(start, end) {
    return data.filter((d) => d.year >= start && d.year <= end);
  }

  function filter(start, end) {
    setIncs(data.filter((d) => d.year >= start && d.year <= end));
  }

  return (
    <Layout>
      <main className="page__container">
        <h1 className="page__title incidents__title">Incidents</h1>
        <ul className="incidents__nav">
          {menu.map((m, i) => {
            return (
              <li
                className={selected === i ? "selected" : ""}
                key={i}
                onClick={() => {
                  filter(m[0], m[1]);
                  setSelected(i);
                }}
              >
                {m[2]}
              </li>
            );
          })}
        </ul>
        <section className="incidents">
          {incs.map((d, i) => (
            <Incident key={i} incident={d} />
          ))}
        </section>
      </main>
    </Layout>
  );
}

export default Incidents;
