import { withPrefix } from "gatsby";

export const data = [
  {
    title: "El Paso Refinery – Aruba, Dutch Antilles",
    header: "El Paso Refinery – Aruba, Dutch Antilles 2001",
    text:
      "A faulty valve led to an oil spill that auto-ignited. The flames spread to nearby equipment and set off a series of explosions, making it difficult for firefighters to battle the flames. The fire was extinguished after three and a half hours. Three people suffered minor injuries during evacuation. Experts estimated property damage at USD 159 million.",
    year: 2001,
  },
  {
    title: "Los Angeles Refinery – Carson, California",
    header: "Los Angeles Refinery – Carson, California 2001",
    text:
      "A piping leak caused a fire to break out in the refinery’s coker unit. More than 100 firefighters battled the blaze for three hours. The refinery’s owner, Tosco Corp., shut down the coker unit for approximately two months. Property damage was estimated at USD 120 million.",
    img: withPrefix("/images/incidents/2001-2005/los-angeles-refinery.jpg"),
    year: 2001,
  },
  {
    title: "Citgo Refinery – Lemont, Illinois",
    header: "Citgo Refinery – Lemont, Illinois 2001",
    text:
      "A ruptured pipe elbow in the refinery’s crude-distillation unit caused a fuel spill that auto-ignited. Firefighters put out the flames in a few hours. The blaze exacerbated pre-existing structural flaws in a distillation tower, causing the structure to collapse three days later. Citgo suffered property damages estimated at USD 145 million and had to shut down the crude unit for more than nine months. In 2006, Citgo won USD 387 million in a compensation lawsuit against Babcock & Wilcox, the company that manufactured the pipe.",
    year: 2001,
  },
  {
    title: "Orion Refinery – Norco, Louisiana",
    header: "Orion Refinery – Norco, Louisiana 2001",
    text:
      "Lightning ignited this blaze, which holds the Guinness World Record for biggest fuel-tank fire in history. After 12 hours of preparation, firefighters defeated the flames in 65 minutes by continuously spraying foam while pumping residual gasoline out of the 82m-diameter tank. Roughly one third of its 10 million-gallon (37 million-liter) capacity went up in smoke. Direct damage was estimated at USD 50 million, which does not include production shortfall, brand-image depreciation and environmental pollution.",
    img: withPrefix("/images/incidents/2001-2005/orion-refinery-fire.jpg"),
    year: 2001,
  },
  {
    title: "Petroleum Fuel and Terminal Oil Co. – Granite City, Illinois",
    header: "Petroleum Fuel and Terminal Oil Co. – Granite City, Illinois 2001",
    text:
      "Disaster struck this facility twice during the summer of 2001. An unexplained fire burned up 400,000 gallons of asphalt fuel in a 15,900m3 tank. After 15 hours of smoke and flames, firefighters applied foam and extinguished the blaze in 45 minutes. A month later, a second fire started as workers tried to warm up the remaining asphalt in order to transfer it into a new tank. This second fire consumed 378,000 gallons of asphalt.",
    img: withPrefix("/images/incidents/2001-2005/asphaltfire.jpg"),
    year: 2001,
  },
  {
    title: "Trzebinia Refinery – Malopolska, Poland",
    header: "Trzebinia Refinery – Malopolska, Poland 2002",
    text:
      "A lightning bolt triggered this full-surface fire at a 30m-diameter tank with an internal floating roof. A semi-fixed extinguishment system began applying foam, but the process had to be terminated immediately due to system damage. It took 362 firefighters five hours to put out the flames by blanketing them with 109.5 tons of foam, using 13 foam monitors supplied by 35 vehicles. Damages were estimated at USD 2.3 million.",
    img: withPrefix("/images/incidents/2001-2005/trzebinia.jpg"),
    year: 2002,
  },
  {
    title: "Cabras Island – Hagatna, Guam",
    header: "Cabras Island – Hagatna, Guam 2002",
    text:
      "A buildup of static electricity sparked this fire as Super Typhoon Pongsona pounded the island. The flames persisted for six days, destroying three fuel tanks and prompting officials to ban gasoline sales to the public. The first tank to catch fire contained less than 15% of its total capacity of unleaded gasoline. Damage was estimated at USD 20 million.",
    img: withPrefix("/images/incidents/2001-2005/cabras-island.jpg"),
    year: 2002,
  },
  {
    title: "Houston Fuel and Oil Terminal Fire – Houston, Texas",
    header: "Houston Fuel and Oil Terminal Fire – Houston, Texas 2002",
    text:
      "The fire started after a fuel pipeline burst. The flames spread to a giant tank containing 30,000 barrels of residual fuel oil, an asphalt-like residue. Firefighters extinguished the blaze nearly five hours later with the help of 20 fire and foam trucks and a local emergency team. The accident cost at least USD 1.2 million.",
    img: withPrefix("/images/incidents/2001-2005/houston-texas.jpg"),
    year: 2002,
  },
  {
    title: "SAMIR Refinery –Mohammedia, Morocco",
    header: "SAMIR Refinery –Mohammedia, Morocco 2002",
    text:
      "Heavy rains triggered a flood that engulfed the Mohammedia refinery, the biggest in Morocco. Waste oil floating on the water’s surface ignited when it came into contact with hot refinery components and quickly developed into a massive blaze. Two people were killed and three were reported missing. It took more than a year for SAMIR to restore production capacity of 125,000 barrels per day. Experts said the blaze inflicted as much as $200 million in property damages.",
    img: withPrefix("/images/incidents/2001-2005/samir-refinery.jpg"),
    year: 2002,
  },
  {
    title: "Raudhatain Oil Field – Kuwait",
    header: "Raudhatain Oil Field – Kuwait 2002",
    text:
      "The blaze broke out after a leak from an underground oil pipeline spread to a power substation, causing a massive explosion that killed four people. The ensuing fire spread to an oil-gathering center and a gas-booster station. It took firefighters two days to extinguish the flames, which injured a further 19 people. Experts estimated property damages at USD 180 million.",
    year: 2002,
  },
  {
    title: "Idemitsu Kosan Refinery – Tomakomai, Hokkaido, Japan",
    header: "Idemitsu Kosan Refinery – Tomakomai, Hokkaido, Japan 2003",
    text:
      "A blaze broke after a powerful earthquake struck, causing a floating roof to sink into a storage tank containing naphtha. Some 134 firefighters swung into action, but high winds and the force of the fire made it difficult for them to apply foam where necessary. The flames died out after 44 hours, consuming all 26,000 kiloliters of naphtha. The foam contaminated nearby residential areas. The accident caused damages of USD 88.7 million.",
    img: withPrefix("/images/incidents/2001-2005/japan.jpg"),
    year: 2003,
  },
  {
    title: "Digboi Refinery – Guwahati, India",
    header: "Digboi Refinery – Guwahati, India 2003",
    text:
      "This disaster was the result of a terrorist attack by the United Liberation Front of Assam, whose rebels fired a mortar at a petroleum tank at night. Witnesses said the flames shot up as high as 100m. Some 70 firefighters worked for 56 hours to prevent the blaze from spreading to other tanks. The preliminary loss was estimated at over INR 100 million (USD 1.6 million).",
    img: withPrefix("/images/incidents/2001-2005/india.jpg"),
    year: 2003,
  },
  {
    title: "Gdansk Oil Refinery – Gdansk, Poland",
    header: "Gdansk Oil Refinery – Gdansk, Poland 2003",
    text:
      "A lightning bolt triggered this full-surface fire at a 30m-diameter tank with an internal floating roof. A semi-fixed extinguishment system began applying foam, but the process had to be terminated immediately due to system damage. It took 362 firefighters five hours to put out the flames by blanketing them with 109.5 tons of foam, using 13 foam monitors supplied by 35 vehicles. Damages were estimated at USD 2.3 million.",
    img: withPrefix("/images/incidents/2001-2005/poland.jpg"),
    year: 2003,
  },
  {
    title: "Athabasca Oil Sands Project – Fort McMurray, Canada",
    header: "Athabasca Oil Sands Project – Fort McMurray, Canada 2003",
    text:
      "This facility, majority-owned by Shell Canada, had been operational for just over a week when a hydrocarbon leak sparked explosions and a fire. The blaze broke out near a solvent-recovery unit at the facility’s Muskeg River mine, seriously damaging infrastructure and forcing a delay in the production of bitumen. Firefighters extinguished the flames in two hours and only one minor injury was reported. Experts put property damages at USD 120 million.",
    year: 2003,
  },
  {
    title: "Formosa Plastics PVC Unit – Illiopolis, Illinois",
    header: "Formosa Plastics PVC Unit – Illiopolis, Illinois 2004",
    text:
      "Five workers died and two others were critically injured when an explosion occurred in a polyvinyl chloride (PVC) production unit. The blast demolished 50-75% of the plant. Total damage was estimated at USD 100 million.",
    img: withPrefix("/images/incidents/2001-2005/illinois.jpg"),
    year: 2004,
  },
  {
    title: "Port Kembla Industrial Site – New South Wales, Australia",
    header: "Port Kembla Industrial Site – New South Wales, Australia 2004",
    text:
      "A welding mishap caused an explosion in a tank holding 5 million liters of ethanol. The blast blew the lid off the 32m-diameter tank and shook buildings as far as 25km away. It took firefighters 20 hours to extinguish the resulting blaze, which caused estimated losses of USD 8 million. The tank’s owner, Manildra Inc., was fined AUD 160,000 (USD 118,000) while the manager faced penalties of AUD 16,000.",
    img: withPrefix("/images/incidents/2001-2005/port-kembla.jpg"),
    year: 2004,
  },
  {
    title: "Petrowidada Chemical Plant – Gresik, Indonesia",
    header: "Petrowidada Chemical Plant – Gresik, Indonesia 2004",
    text:
      "An overheated machine touched off massive explosion that one witness compared to a volcano eruption. The resulting fire engulfed at least two chemical tanks. The disaster killed two people, injured 68 and destroyed five nearby houses. Authorities evacuated some 250 people from the surrounding area. Experts put property damages as high as USD 100 million.",
    img: withPrefix("/images/incidents/2001-2005/petrowidada.jpg"),
    year: 2004,
  },
  {
    title: "BP America Refinery – Texas City, Texas",
    header: "BP America Refinery – Texas City, Texas 2005",
    text:
      "One of the worst industrial disasters in recent U.S. history occurred when a hydrocarbon vapor cloud caught fire and exploded, spreading flames over a 19,000m2 area. The blast killed 15 people and injured 170. Federal authorities slapped BP with a USD 21 million fine and issued 270 safety citations. In 2009, authorities determined that BP still had not resolved the safety problems and imposed additional penalties of USD 87.4 million. The two sides settled most of the outstanding violations by 2012. BP paid a total of USD 84.6 million in fines and suffered cumulative financial losses exceeding USD 1.5 billion.",
    img: withPrefix("/images/incidents/2001-2005/texas-city.jpg"),
    year: 2005,
  },
  {
    title: "Buncefield Oil Terminal – Buncefield, England",
    header: "Buncefield Oil Terminal – Buncefield, England 2005",
    text:
      "Overflow from a petroleum tank led to the rapid formation of a fuel-air vapor cloud that ignited, causing multiple explosions. The fire spread to 20 storage tanks and consumed 273 million liters of fuel. It took 180 firefighters four days to put out the flames with support from 25 fire engines and 20 support vehicles. Some 244 people required medical aid. Damages were estimated at GBP 1 billion (USD 1.5 billion). Investigators later determined that a large quantity of fuel had leached out during the blaze, contaminating water resources.",
    img: withPrefix("/images/incidents/2001-2005/england.jpg"),
    year: 2005,
  },
  {
    title: "Suncor Energy – Fort McMurray, Canada",
    header: "Suncor Energy – Fort McMurray, Canada 2005",
    text:
      "Suncor company officials blamed this fire on a defective nozzle at the oil-sands refinery. Firefighters extinguished the flames after nine hours in temperatures as low as -35 celsius; the water they used froze, inflicting additional damage on the facility. Oil production at the unit dropped more than 50% for eight months. Insurers paid Suncor CAD 979 million (USD 813 million) to cover business interruption and CAD 148 million for property damages. In late 2006, Suncor sued the companies whose engineers had designed the facility, including Bantrel Co. and Bechtel Group Inc., for CAD 630 million on behalf of its insurers.",
    year: 2005,
  },
  {
    title: "ISAB Nord Refinery – Priolo Gargallo, Italy",
    header: "ISAB Nord Refinery – Priolo Gargallo, Italy 2006",
    text:
      "A fire erupted after a pipe carrying crude oil sprung a leak. While firefighters initially were able to contain the flames, excessive heat caused additional pipelines to explode, prompting authorities to shut down the refinery and adjacent industrial plants The blaze lasted two days, injuring six people. Experts estimated property damages at USD 110 million.",
    img: withPrefix("/images/incidents/2006-2008/isab.jpg"),
    year: 2006,
  },

  {
    title: "Mazeikiu Nafta – Mazeikiu, Lithuania",
    header: "Mazeikiu Nafta – Mazeikiu, Lithuania 2006",
    text:
      "Flames ravaged Lithuania’s only refinery after an old vacuum-distillation unit caught fire, allowing a column to collapse onto a heat-exchange train. As many as 100 firefighters battled the blaze through the night. Analysts put property damages as high as USD 142.9 million.",
    img: withPrefix("/images/incidents/2006-2008/mazeikiu-nafta.jpg"),
    year: 2006,
  },

  {
    title: "Engen Refinery – Durban, South Africa",
    header: "Engen Refinery – Durban, South Africa 2007",
    text:
      "Lighting hit a tank containing 7.5 million liters of refined petrol, igniting a blaze. The floating roof and seals collapsed into the tank. Firemen struggled against strong winds as the flames lingered on for five days. When it was all over, the fire had consumed 60 percent of the tank’s contents and inflicted losses of about USD 9.7 million. It was the second major fire to hit the Durban area in less than two months.",
    img: withPrefix("/images/incidents/2006-2008/engen-refinery.jpg"),
    year: 2007,
  },

  {
    title: "Gary-Williams Refinery – Wynnewood, Oklahoma",
    header: "Gary-Williams Refinery – Wynnewood, Oklahoma 2007",
    text:
      "A storage tank holding 8,000 barrels of naphtha burst into flames when lighting struck. The fire persisted through the day, spreading to a second tank that evening. It took firefighters two days to put out the fire, the second to hit the Wynnewood facility in a year. The refinery lost 25 million liters of naphtha, 14 million liters of diesel and 25 million liters of gasoline. Total financial losses came to around USD 15 million.",
    img: withPrefix("/images/incidents/2006-2008/wynnewood.jpg"),
    year: 2007,
  },
  {
    title: "Chevron Refinery – Pascagoula, Mississippi",
    header: "Chevron Refinery – Pascagoula, Mississippi 2007",
    text:
      "Authorities decided that the safest way to extinguish this fire was a “controlled burnout.” In other words, they allowed the flames to continue until they had consumed all the flammable material. Estimated property damages were as high as USD 230 million.",
    img: withPrefix("/images/incidents/2006-2008/chevron-refinery.jpg"),
    year: 2007,
  },
  {
    title: "Shin-Etsu Chemical Factory – Joetsu, Japan",
    header: "Shin-Etsu Chemical Factory – Joetsu, Japan 2007",
    text:
      "Static electricity at a methylcellulose facility sparked a powder-dust explosion and a fire. Firefighters extinguished the flames seven hours later, but were unable to prevent the blaze from destroying most of a four-story building. Seventeen people were injured and authorities suspended operations at the unit for two months. Property damage was estimated at USD 240 million.",
    year: 2007,
  },
  {
    title: "Tanjung Langsat Port – Johor, Malaysia",
    header: "Tanjung Langsat Port – Johor, Malaysia 2008",
    text:
      "A fire broke out in a 16,000-ton gasoline tank. The next day, the intense heat caused an adjacent tank containing naphthalene to ignite. Firefighters attempted to extinguish the blaze by pumping in water from the sea, to no avail. The flames burned on for 67 hours, dying out only after they had consumed all the tanks’ contents – 17.8 million liters of unleaded petrol and 8 million liters of naphthalene. Total damages were estimated at USD 40 million.",
    img: withPrefix("/images/incidents/2006-2008/malaysia.jpg"),
    year: 2008,
  },
  {
    title: "Alon USA Refinery – Big Spring, Texas",
    header: "Alon USA Refinery – Big Spring, Texas 2008",
    text:
      "This fire erupted after a propylene tank released vapor that came into contact with refinery equipment, causing an explosion. Firefighters brought the blaze under control about an hour after it started, but the blast inflicted severe damage on the facility as well as homes and businesses in the surrounding area. Five people were injured. Juries found Alon USA liable for negligence and awarded victims compensation for their damaged properties. Total property losses were estimated at USD 380 million.",
    img: withPrefix("/images/incidents/2006-2008/alon-usa-refinery.jpg"),
    year: 2008,
  },
  {
    title: "Ras Lanuf Oil Complex – Ras Lanuf, Libya",
    header: "Ras Lanuf Oil Complex – Ras Lanuf, Libya 2008",
    text:
      "A storage tank went up in flames during routine maintenance operations. The blaze was blamed on hot work. Some 1,000 firefighters managed to contain the blaze to a single tank, but were unable to extinguish it for more than nine days. The fire destroyed 60,000m3 of crude oil. Total damages were estimated at USD 50 million.",
    year: 2008,
  },
  {
    title: "Bakersfield Explosion – California",
    header: "Bakersfield Explosion – California 2008",
    text:
      "Three oil tanks exploded at a facility southeast of Bakersfield. Fire officials decided to let the fires burn themselves out at two large tanks and one smaller tank. Total damages were estimated at USD 600,000.",
    img: withPrefix("/images/incidents/2006-2008/bakersfield.jpg"),
    year: 2008,
  },
  {
    title: "Caribbean Petroleum Corp. Refinery – Bayamon, Puerto Rico",
    header: "Caribbean Petroleum Corp. Refinery – Bayamon, Puerto Rico 2009",
    text:
      "Due to a faulty gauge, refinery workers did not notice that an overfilled fuel tank was emitting deadly vapor. The fumes caught fire, causing an explosion equivalent to a 2.8-magnitude earthquake. The flames spread to fuel containers, raging for 66 hours until there was no more material left to burn. The catastrophe destroyed 20 tanks, damaged another 12, and battered hundreds of homes and businesses in a 2km radius. The Fort Buchanan military facility suffered over USD 5 million in damages. In addition, 136 million liters of petroleum were released into the environment. The Caribbean Petroleum Corporation, facing an estimated USD 200 million in damages, filed for bankruptcy in 2010.",
    img: withPrefix("/images/incidents/2009-2011/puerto-rico.jpg"),
    year: 2009,
  },
  {
    title: "IOC Terminal Jaipur – Mohanpura, India",
    header: "Caribbean Petroleum Corp. Refinery – Bayamon, Puerto Rico 2009",
    text:
      "An explosion ripped through the Indian Oil Corporation depot, unleashing a fire in a giant tank. The incident occurred while workers were transferring gasoline from the depot to a pipeline. Flames raged on for 11 days, killing 12 people and injuring at least 300. Police arrested nine senior company officials including the general manager on charges of criminal negligence. The fire resulted in the loss of 18 million liters of petroleum, 2 million liters of kerosene, 40 million liters of high-speed diesel, and 2.8 million liters of interface. Total estimated losses came to INR 18 billion (USD 280 million).",
    img: withPrefix("/images/incidents/2009-2011/jaipur.jpg"),
    year: 2009,
  },
  {
    title: "BOPEC Terminal – Bonaire, Dutch Antilles",
    header: "BOPEC Terminal – Bonaire, Dutch Antilles, 2010",
    text:
      "Two tanks caught fire during an electrical storm. Firefighters battled the blaze for three days but could not extinguish it until the tank had burned out. The incident resulted in the loss of 32 million liters of naphtha and caused estimated financial damages of USD 35 million. Dutch investigators later reported that the plant’s firefighting system was not working due to improper maintenance.",
    img: withPrefix("/images/incidents/2009-2011/bopec.jpg"),
    year: 2010,
  },
  {
    title: "Colonial Pipelines – Greensboro, North Carolina",
    header: "Colonial Pipelines – Greensboro, North Carolina 2010",
    text:
      "A tank containing 20,000 barrels of gasoline was hit by lightning and caught fire. Firefighters worked for six hours to contain the blaze, which eventually burned itself out. Total damages were estimated at USD 3.5 million.",
    img: withPrefix("/images/incidents/2009-2011/NorthCarolina.jpg"),
    year: 2010,
  },
  {
    title: "Pembroke Refinery – Pembroke, Wales",
    header: "Pembroke Refinery – Pembroke, Wales 2011",
    text:
      "Four people were killed and one injured following an explosion in a 730m3 storage tank where maintenance was being carried out. The resulting fire damaged at least two tanks at the facility, which was owned by Chevron at the time. Fire crews backed by 10 fire engines brought the incident under control in 90 minutes. Total damages were estimated at USD 1.6 million.",
    img: withPrefix("/images/incidents/2009-2011/pembroke.jpg"),
    year: 2011,
  },
  {
    title: "Algeciras Bay Fuel Depot – Gibraltar",
    header: "Algeciras Bay Fuel Depot – Gibraltar, 2011",
    text:
      "Welders performing maintenance work in this empty storage tank were apparently unaware that fuel residues can be as deadly as the fuel itself. The heat from their torches ignited the diesel dregs left behind in the container, setting off an explosion that unleashed a huge fire. The flames soon spread to a second tank. Firefighters battled the blaze for two days, but were unable to stop it from destroying both tanks and consuming every drop of the 2.5 million liters of fuel therein. Fuel also spilled into the Algeciras Bay, contaminating several beaches. The total cost of the incident was estimated at USD 2 million.",
    img: withPrefix("/images/incidents/2009-2011/gibraltar.jpg"),
    year: 2011,
  },
  {
    title: "Horizon Primary Upgrader – Fort McKay, Canada",
    header: "Horizon Primary Upgrader – Fort McKay, Canada 2011",
    text:
      "An explosion ripped through the plant, injuring five workers. A fire then started on top of a coke drum, lasting nearly four hours. Authorities decided the safest course of action was to let the fire burn itself out. The value of insured losses was estimated at USD 600 million.",
    year: 2011,
  },
  {
    title: "JX Nippon Refinery – Sendai, Japan",
    header: "JX Nippon Refinery – Sendai, Japan 2011",
    text:
      "A tsunami devastated this facility in the wake of the most powerful earthquake in Japan’s history. Investigators believe the wall of water sent tanker trucks crashing into an oil-shipping facility, which in turn ignited fuel that was floating on the water’s surface. Firefighters were unable to reach the blaze for three days due to warnings of additional tsunamis. The flames completely destroyed a gasoline tank, asphalt tanks, molten-sulfur tanks and the oil-shipping facility. Property damages were estimated at USD 590 million.",
    img: withPrefix("/images/incidents/2009-2011/jx-nippon-refinery.jpg"),
    year: 2011,
  },
  {
    title: "Royal Dutch Shell Refinery – Pulau Bukom, Singapore",
    header: "Royal Dutch Shell Refinery – Pulau Bukom, Singapore 2011",
    text:
      "A blaze erupted in a pump house where workers were de-oiling naphtha from a pipeline. Automatic extinguishers blanketed the area with foam and firefighters blasted it with water, but the flames persisted for 32 hours. The operation involved more than 100 firefighters, 13 fire engines and 21 support vehicles. Shell was forced to shutter its biggest plant and did not resume full operations for more than two months. A year after the disaster, Shell pleaded guilty to safety violations. Property damage was estimated at USD 150 million.",
    img: withPrefix("/images/incidents/2009-2011/royal-dutch.jpg"),
    year: 2011,
  },
  {
    title: "Amuay Refinery – Punto Fijo, Venezuela",
    header: "Amuay Refinery – Punto Fijo, Venezuela, 2012",
    text:
      "A ferocious fire ravaged Venezuela’s biggest refinery, killing at least 47 people, wounding at least 151 and halting operations at the facility. Investigators blamed the disaster on a faulty pump that allowed gas to leak, forming a vapor cloud that ignited and exploded. Eleven storage tanks were destroyed or damaged. A USD 23 million fund was created to help cover cleanup costs and replace more than 200 damaged homes. It took four days for firefighters to quash the flames, resulting in a loss of 105 million liters of product. Damages at the refinery alone were seen at $1.1 billion.",
    img: withPrefix("/images/incidents/2012-2014/venezuela.jpg"),
    year: 2012,
  },
  {
    title: "Nasr Oil Company – Suez, Egypt",
    header: "Nasr Oil Company – Suez, Egypt 2012",
    text:
      "An explosion and fire raged through one of Egypt’s biggest refineries, killing two people and injuring 72. The blaze lasted for four days, consuming 24 million liters of product and destroying at least two storage tanks. Damages were estimated at USD 30 million.",
    img: withPrefix("/images/incidents/2012-2014/suez.jpg"),
    year: 2012,
  },
  {
    title: "BST Elastomers Factory – Map Ta Phut, Thailand",
    header: "BST Elastomers Factory – Map Ta Phut, Thailand 2012",
    text:
      "An explosion tore through this rubber plant while workers were cleaning out a storage tank using toulene, a highly flammable solvent. Emergency workers extinguished the ensuing blaze within a few hours; however, nine people were killed on site and three others died in hospital. At least 129 people sustained injuries and authorities ordered thousands of nearby residents to evacuate. Property damages were estimated at USD 143 million.",
    img: withPrefix("/images/incidents/2012-2014/elastomers.jpg"),
    year: 2012,
  },
  {
    title: "Bangchak Petroleum Refinery – Bangkok, Thailand",
    header: "Bangchak Petroleum Refinery – Bangkok, Thailand 2012",
    text:
      "A suspected oil leak triggered an explosion and fire that severely damaged the plant’s crude-distillation unit. The incident forced the plant to shut down for several weeks and drove down production to 80,000 barrels/day, roughly two-thirds of the refinery’s capacity. Investigators put property damages at USD 140 million.",
    img: withPrefix("/images/incidents/2012-2014/bangchak-petroleum.jpg"),
    year: 2012,
  },
  {
    title: "IOC Hazira Terminal – Ichapur, India",
    header: "IOC Hazira Terminal – Ichapur, India 2013",
    text:
      "Three people were killed in a fire that broke out in a storage tank at this Indian Oil Corporation terminal. The blaze spread to a second petroleum tank. Firefighters brought the flames under control after more than 24 hours. The conflagration destroyed 75,000 barrels of product and inflicted estimated losses of USD 16 million.",
    img: withPrefix("/images/incidents/2012-2014/india.jpeg"),
    year: 2013,
  },
  {
    title: "Puerto La Cruz Refinery – Anzoategui, Venezuela",
    header: "Puerto La Cruz Refinery – Anzoategui, Venezuela, 2013",
    text:
      "Lightning hit a storage tank, igniting a fire that forced authorities to evacuate the surrounding area. The blaze was extinguished after four hours.",
    img: withPrefix("/images/incidents/2012-2014/venezuela2.jpg"),
    year: 2013,
  },
  {
    title: "Williams Olefins Plant – Geismar, Louisiana",
    header: "Williams Olefins Plant – Geismar, Louisiana 2013",
    text:
      "An explosion and fire ripped through the plant, killing two people and injuring 167. The blast caused an estimated USD 102 million in property damage and USD 384 million in business interruption. Total losses were estimated at USD 580 million. Juries awarded a combined USD 30 million to the first eight workers who sued for damages, concluding that officials at both the plant and its parent company had been negligent and knew with substantial certainty that a deadly fire could occur.",
    img: withPrefix("/images/incidents/2012-2014/williams.jpg"),
    year: 2013,
  },
  {
    title: "Stanlow Refinery – Ellesmere Port, England",
    header: "Stanlow Refinery – Ellesmere Port, England 2013",
    text:
      "Investigators said the blast occurred after flammable hydrocarbons had entered an unused furnace. Heat from an adjacent furnace then caused the equipment to explode. Firefighters extinguished the blaze promptly, but the refinery, England’s second-largest, was forced to halt production for two weeks. The facility’s operator, Essar Oil Ltd., pleaded guilty in 2017 to violating hazard-control regulations and was fined GBP 1.65 million (USD 1.2 million). The incident inflicted damages estimated at USD 150 million.",
    year: 2013,
  },
  {
    title: "YPF La Plata Refinery – Ensenada, Argentina",
    header: "YPF La Plata Refinery – Ensenada, Argentina 2013",
    text:
      "A coke oven at Argentina’s biggest refinery caught fire amid torrential rains that had disrupted the facility’s systems. Firefighters brought the flames under control in six hours. The blaze destroyed the coke furnace and also damaged a distillation unit. Production dropped by as much as 45%. At the end of 2014, YPF recorded insurance payouts of USD 556 million for damaged facilities and business interruption stemming from the fire.",
    img: withPrefix("/images/incidents/2012-2014/ypfplata.jpg"),
    year: 2013,
  },
  {
    title: "Milazzo Oil Refinery – Sicily, Italy",
    header: "Milazzo Oil Refinery – Sicily, Italy 2014",
    text:
      "A fire broke out during nighttime maintenance work. Firefighters kept the fire under control for 14 hours until it had consumed all 86,000 liters of product. Experts estimated total damages at USD 1.6 million.",
    img: withPrefix("/images/incidents/2012-2014/india2.jpeg"),
    year: 2014,
  },
  {
    title: "Midwest Fuel Plant – La Crosse, Wisconsin",
    header: "Midwest Fuel Plant – La Crosse, Wisconsin 2014",
    text:
      "A malfunctioning gauge led to an explosion at a 132,000-gallon (500,000-liter) tank, which contained around 7,000 gallons of an asphalt-diesel fuel mixture. One person suffered minor injuries in the blast. Experts estimated total costs at around USD 200,000.",
    img: withPrefix("/images/incidents/2012-2014/lacrosse.jpg"),
    year: 2014,
  },
  {
    title: "Port of Es Sider – Ras Lanuf, Libya",
    header: "Port of Es Sider – Ras Lanuf, Libya 2014",
    text:
      "Libya Dawn, a pro-Islamist coalition, fired rockets at Libya’s biggest oil port from speedboats, igniting an oil tank and killing at least 19 soldiers. The blaze spread to seven tanks. Despite firefighters’ best efforts, the flames persisted for more than a week until all 295 million liters of fuel had burned out and the tanks were destroyed. Total damages were estimated at USD 305 million. Fighting between government forces and militias at the end of 2014 reduced Libya’s crude output to 352,000 barrels a day.",
    img: withPrefix("/images/incidents/2012-2014/port-es-sider.jpg"),
    year: 2014,
  },
  {
    title: "SAMIR Refinery – Mohammedia, Morocco",
    header: "SAMIR Refinery – Mohammedia, Morocco 2014",
    text:
      "This blaze resulted from a gas leak that occurred while workers were performing a liquefied propane-filling operation. It quickly spread to two storage tanks, destroying both of them. One worker died and two suffered severe burns. Experts estimated property damages at USD 15 million.",
    img: withPrefix("/images/incidents/2012-2014/samir.jpg"),
    year: 2014,
  },
  {
    title: "YPF Cerro Divisadero Plant – Mendoza, Argentina",
    header: "YPF Cerro Divisadero Plant – Mendoza, Argentina 2014",
    text:
      "A fire swept over six of the 10 oil tanks at this crude-treatment facility. The blaze raged for three days with such ferocity that it partially melted the containers. Seventeen people were injured. YPF was forced to halt operations at the Mendoza plant, which had been responsible for 3.8% of the state-owned company’s output. Property damages were seen at USD 180 million.",
    img: withPrefix("/images/incidents/2012-2014/ypf.jpg"),
    year: 2014,
  },
  {
    title: "LyondellBasell – Berre-l‘Etang, France",
    header: "LyondellBasell – Berre-l‘Etang, France 2015",
    text:
      "Police say a saboteur set off two explosions that rocked this petrochemical plant near Marseilles. The ensuing fire destroyed one tank holding 11,000m3 of gasoline and another containing 42,000m3 of naphtha. Some 120 firefighters battled the blaze for several hours. Total costs were estimated at USD 2.5 million.",
    img: withPrefix("/images/incidents/2015-2017/france.jpg"),
    year: 2015,
  },
  {
    title: "Husky Lima Refinery – Lima, Ohio",
    header: "Husky Lima Refinery – Lima, Ohio 2015",
    text:
      "An isocracker unit exploded following routine maintenance, triggering a blaze. Firefighters extinguished it after a day, but remained on the scene for 36 hours to provide aid. Experts put damages at USD 75 million.",
    img: withPrefix("/images/incidents/2015-2017/ohio.jpg"),
    year: 2015,
  },
  {
    title: "Aden Refinery – Aden, Yemen",
    header: "Aden Refinery – Aden, Yemen 2015",
    text:
      "Militants with the Houthis, a rebel group at war with Yemen’s government, fired rockets at the facility, unleashing a fire. Flames broke out in two fuel-storage tanks and a pipeline linking the refinery to the city port. The attack killed the refinery’s director of operations. Two days later, Houthis launched a second missile that targeted an empty storage tank at the refinery port. Total costs were estimated at USD 2 million.",
    img: withPrefix("/images/incidents/2015-2017/aden.jpg"),
    year: 2015,
  },
  {
    title: "Ultracargo Fire – Port of Santos, Brazil",
    header: "Ultracargo Fire – Port of Santos, Brazil 2015",
    text:
      "Over 100 firefighters were deployed to battle a fire at this fuel-storage facility near Santos, Latin America’s largest port. The fire spread to four tanks and lasted three days. People in the surrounding area were evacuated. Total damages were estimated at USD 2.5 million.",
    img: withPrefix("/images/incidents/2015-2017/brasil-port-of-santos.jpg"),
    year: 2015,
  },
  {
    title: "Chempark Zaluzi Plant – Litvinov, Czech Republic",
    header: "Chempark Zaluzi Plant – Litvinov, Czech Republic 2015",
    text:
      "A propylene leak touched off an explosion at a steam-cracker unit. The blast interrupted utility lines, which eventually caused a quench-oil leak that formed a pool and ignited. The blaze damaged four of the facility’s 10 cracker furnaces. The plant’s owner, Unipetrol AS, was forced to suspend all petrochemicals production at Litvinov. Property damages were estimated at USD 180 million.",
    img: withPrefix("/images/incidents/2015-2017/chempark-zaluzi.jpg"),
    year: 2015,
  },
  {
    title: "Matautu Wharf – Apia, Samoa",
    header: "Matautu Wharf – Apia, Samoa 2016",
    text:
      "A fuel-storage tank exploded during maintenance, killing one worker and injuring another. Firefighters put out the blaze in 16 hours, but not before it had destroyed 206,000 liters of product as well as the tank. Thousands of people were evacuated from the area. Total damages were estimated at USD 250,000.",
    img: withPrefix("/images/incidents/2015-2017/samoa.jpg"),
    year: 2016,
  },
  {
    title: "Jingjiang, Jiangsu Province, China",
    header: "Jingjiang, Jiangsu Province, China 2016",
    text:
      "A warehouse containing chemicals and fuel has exploded in the city of Jingjiang on April 22. A firefighter died while battling the blaze, which took 16 hours to extinguish. One storage tank exploded and several more burned out. Experts said total costs were at least USD 25 million.",
    img: withPrefix("/images/incidents/2015-2017/china.jpg"),
    year: 2016,
  },
  {
    title: "Keamari Oil Terminal – Karachi, Pakistan",
    header: "Keamari Oil Terminal – Karachi, Pakistan 2016",
    text:
      "At least two workers were killed and several others injured when an explosion ripped through a tank containing chemicals. Firefighters decided to let the flames consume all 1,000 tons of material inside the tank, along with the tank itself. The blaze burned itself out after 34 hours. Total costs were estimated at USD 1.5 million.",
    img: withPrefix("/images/incidents/2015-2017/pakistan.jpg"),
    year: 2016,
  },
  {
    title: "Jurong Aromatics Corp. – Jurong Island, Singapore",
    header: "Jurong Aromatics Corp. – Jurong Island, Singapore 2016",
    text:
      "Firefighting teams extinguished this blaze in less than five hours and managed to contain it to a single 40m-diameter tank. The operation involved five fire engines, 29 support vehicles and three Singapore-made firefighting cars known as Red Rhinos. Total costs were estimated at USD 9 million.",
    img: withPrefix("/images/incidents/2015-2017/jurong.jpg"),
    year: 2016,
  },
  {
    title: "Bazan Oil Refinery – Haifa, Israel",
    header: "Bazan Oil Refinery – Haifa, Israel 2016",
    text:
      "A blaze broke out in a 12,000m3 benzene-storage container that was about 10% full. Firefighters had the blaze under control within a few hours. Experts estimated total damages at USD 4 million.",
    img: withPrefix("/images/incidents/2015-2017/israel.jpg"),
    year: 2016,
  },
  {
    title: "Puma Energy Plant – Puerto Sandino, Nicaragua",
    header: "Puma Energy Plant – Puerto Sandino, Nicaragua 2016",
    text:
      "A fire broke out in a fuel-storage tank following an explosion at the Puma plant, Nicaragua’s sole oil refinery. A second tank ignited the next day. Puma Energy and Williams Fire & Hazard Control decided to let the fire burn itself out. Both of the damaged fuel tanks had a capacity of about 144,000 barrels. Costs were estimated at USD 30 million.",
    img: withPrefix("/images/incidents/2015-2017/nicaragua.jpg"),
    year: 2016,
  },
  {
    title: "Mohawk Asphalt Emulsions – Glenville, New York",
    header: "Mohawk Asphalt Emulsions – Glenville, New York 2017",
    text:
      "A heater operating inside the plant caused a cleaning solution containing kerosene to overheat and catch fire. One tank containing between 5,000 and 7,000 gallons of the solution burned out. Afterwards, the plant’s owner said it would cease production of the kerosene solution at this facility. Experts estimated total damages at USD 1 million.",
    img: withPrefix("/images/incidents/2015-2017/glenville.jpg"),
    year: 2017,
  },
  {
    title: "Port of Mumbai – Butcher Island, India",
    header: "Port of Mumbai – Butcher Island, India 2017",
    text:
      "A lightning bolt touched off this storage-tank fire at Mumbai’s Butcher Island. The blaze persisted for four days even as 60 firefighters worked to contain it. More than 10 million liters of diesel went up in smoke. Experts put damages at USD 20 million.",
    img: withPrefix("/images/incidents/2015-2017/butcher-island.jpg"),
    year: 2017,
  },
  {
    title: "Iranian Oil Pipelines – Tehran, Iran",
    header: "Iranian Oil Pipelines – Tehran, Iran 2017",
    text:
      "A fire broke out after lightning struck an oil-storage tank. Some 400,000 liters of crude oil evaporated during the incident. Extinguishment took about four hours. Experts estimated damages at USD 6 million.",
    img: withPrefix("/images/incidents/2015-2017/tehran.jpg"),
    year: 2017,
  },
  {
    title: "Salina Cruz Refinery – Oaxaca, Mexico",
    header: "Salina Cruz Refinery – Oaxaca, Mexico 2017",
    text:
      "The fire broke out after crude oil began leaking from a 500,000-liter storage tank. One person died and at least nine people were injured. Total damages were estimated at USD 600.000.",
    img: withPrefix("/images/incidents/2015-2017/mexico.jpg"),
    year: 2017,
  },
  {
    title: "Kuala Garing Factory, Selangor, Malaysia",
    header: "Kuala Garing Factory, Selangor, Malaysia 2017",
    text:
      "An explosion rocked a diesel-storage tank at a cement factory, knocking three maintenance workers into the tank and setting it ablaze. A team of 30 firemen put out the fire in an hour. The company then brought in tanker trucks to pump out 725,000 liters of diesel so authorities could recover the victims’ bodies.",
    img: withPrefix("/images/incidents/2015-2017/malaysia.jpg"),
    year: 2017,
  },
  {
    title: "Husky Energy Refinery fire, Wisconsin, USA",
    header: "Husky Energy Refinery fire, Wisconsin, USA 2018",
    text:
      "An initial explosion have hit a tower, near giant asphalt storage tanks. A storage tank was punctured, and a second fire erupted. The explosion injured 21 people at the plant and led to the evacuation of nearly the entire city of Superior. The refinery can process up to 38,000 barrels of oil a day. Husky shares fell 8 percent to C$17.54 on the Toronto Stock Exchange, the same day it reported financial results. The explosion and fire at Husky Energy’sSuperior refinery resulted in $27 million in damage and $53 million in expenses, according to the company’s second quarter results.",
    img: withPrefix("/images/incidents/2018-2019/husky.jpg"),
    year: 2018,
  },
  {
    title: "Tankstore Fire, Pulau Basang, Singapore",
    header: "Tankstore Fire, Pulau Basang, Singapore 2018",
    text:
      "A fire had broken out at an oil storage tank at Tankstore’s Pulau Busing terminal in Singapore. Firefighters took six hours hours to extinguish the blaze. The terminal has a total capacity of 2 million cubic meters, or 112 tanks, to store petroleum and petrochemical products. The fire-struck storage tank was said to have contained fuel oil. The cause of the fire is not yet known and is under investigation.",
    img: withPrefix("/images/incidents/2018-2019/tankstore.jpg"),
    year: 2018,
  },
  {
    title: "Kemaman Bitumen Company Fire, Terengganu, Malaysia",
    header: "Kemaman Bitumen Company Fire, Terengganu, Malaysia 2018",
    text:
      "A fire broke out and quickly affected 3 storage tanks. Despite the best efforts of 140 firefighters and rescue personnel, the blaze burned for 3 days. 1 storage tank was destroyed and 1 person injured. The three affected oil tanks at the six-tank facility contained a total of around 20,000 litres of crude oil. 14,000 litres of foam concentrate was used by the fire department in the 3 day operation. Experts estimated the cost of the incident to be around: 35 M USD.",
    img: withPrefix("/images/incidents/2018-2019/kemaman-bitumen.jpg"),
    year: 2018,
  },
  {
    title: "Fida Oil Farm, Ras Lanuf, Libya",
    header: "Fida Oil Farm, Ras Lanuf, Libya 2018",
    text:
      "Recent eruptions of violence in Libya’s so-called ‘oil cresent’ between armed forces loyal to Haftar’s Libyan National Army and rival armed groups resulted in another row of burning oil tanks. As a result of the shelling, three oil tanks at the Fida Oil Farm, west of the Ras Lanuf oil terminal were hit and caught fire. Oil tanks are being destroyed almost every year in Libya since 2014 thus damaging the already ailing economy. Tank No. 2 held 200,000 barrels of crude before it was hit, and No. 12 held 240,000 barrels, a firefighting official said, adding crews were running out of foam to contain the blazes. The NOC earlier put the immediate production loss from fighting at the ports at 240,000 barrels per day, expected to rise to 400,000 bpd if they remained shut. Later a third tank caught fire. Experts estimated the damage to be around USD 100 Million.",
    img: withPrefix("/images/incidents/2018-2019/libya.jpg"),
    year: 2018,
  },
  {
    title: "Daehan Oil Corp Fire, Goyang, Korea",
    header: "Daehan Oil Corp Fire, Goyang, Korea, 2018",
    text:
      "One person was arrested when a sky lantern released by a construction worker, caused a massive fire that erupted at a large oil storage facility in Goyang, Gyeonggi Province. The fire, caused USD 3.7 million worth of damage, and it took firefighters 17 hours to extinguish.",
    img: withPrefix("/images/incidents/2018-2019/deahan.jpg"),
    year: 2018,
  },
  {
    title: "Chenjiagang Industrial Park fire, Yancheng, China",
    header: "Chenjiagang Industrial Park fire, Yancheng, China, 2019",
    text:
      "A fire followed by a blast at a Tianjiayi Chemical factory in China’s eastern Jiangsu province, killed 78 people and injured another 640. The Tianjiayi Chemical Company manufactures pesticides. The fire reportedly spread to neighboring factories. The cause of the fire and blast is still being investigated, but the “China Daily” newspaper said: the exact cause of the fire will likely to be identified as a “serious accident caused by human negligence”. Experts estimated the cost of the incident to be more then USD 750 million.",
    img: withPrefix("/images/incidents/2018-2019/chenjiagang.jpg"),
    year: 2019,
  },
  {
    title: "ITC – Deer Park Refinery Fire, Texas, USA",
    header: "ITC – Deer Park Refinery Fire, Texas, USA, 2019",
    text:
      "A leaking tank containing volatile naphtha ignited at the storage terminal of Intercontinental Terminals Co (ITC) and quickly spread to other nearby tanks. Firefighters battled the flames for more than four days but they could not prevent escalation. Inadequate equipment prevented them from mounting a quick and effective foam attack. They also experienced pump malfunctions for six hours (which cost them two extra tanks on fire). Ten storage tanks were destroyed. Experts estimated the damage to be around USD 1 billion.",
    img: withPrefix("/images/incidents/2018-2019/itc.jpg"),
    year: 2019,
  },
  {
    title: "Petro San Felix Refinery Fire, Venezuela",
    header: "Petro San Felix Refinery Fire, Venezuela, 2019",
    text:
      "Two storage tanks at the Petro San Felix oil refinery near San Diego de Cabrutica in Anzoatigui, Venezuela have caught fire. The fire was caused by an act of sabotage. Reports stated that there were no injuries. Experts estimated the cost of the incident around USD 15 million. France’s Total SA, Norway’s Equinor ASA, Russia’s Rosneft and U.S.-based Chevron hold minority stakes in joint ventures with PDVSA that produce crude and operate oil upgraders.",
    img: withPrefix("/images/incidents/2018-2019/petro.jpg"),
    year: 2019,
  },
  {
    title: "Magellan Tank Fire",
    header: "Magellan Tank Fire, 2020",
    text:
      "Contractors from Shelton Services were cleaning Magellan's light crude tank when the fire ignited. Six workers who suffered burn injuries at the Magellan facility were initially taken to the Christus Spohn Hospital-Shoreline. Four patients in critical condition and one in stable condition were later transferred to a San Antonio hospital. One patient had suffered only minor injuries and was discharged. The Magellan tank farm has 60 storage tanks capable of holding 37 million barrels of petroleum and petroleum products and is located near Valero Energy Corp’s and Citgo Petroleum Corp’s refineries in Corpus Christi.",
    img: withPrefix("/images/incidents/2020/magellan.jpg"),
    year: 2020,
  },
  {
    title: "Iseri Iganmu",
    header: "Iseri Iganmu, 2020",
    text:
      "The fire broke out at around 12.20pm local time on 5 November 2020 in a tank at OVH Energy Terminal 1, formerly owned by Oando. The fire was not put out until 4.43am on 7 November. The terminal has a capacity of 7.5 million L and stores refined petroleum products. No casualties have yet been reported as a result of the fire. Eyewitnesses told local media outlets that welding work had been taking place at the tank farm immediately prior to the outbreak of the fire and speculated that welding sparks caused the incident, but this has not been confirmed officially.",
    img: withPrefix("/images/incidents/2020/iseri.jpg"),
    year: 2020,
  },
];
